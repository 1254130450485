import { useTheme } from '@mui/material';

export const RESTRICTION_TO_SPANISH = {
  CONTRACT_SIGNING: 'Firma de Contrato',
  NON_PAYMENT_CLARIFICATION: 'Aclarar impagos en Dicom',
  COLLECTION_DELINQUENT_INVOICE: 'Recaudación de factura morosa en cartera',
  GET_PAYMENT_DATE_FOR_OVERDUE_INVOICES: 'Obtener fecha de pago de facturas morosas',
  TGR_AGREEMENT: 'Realizar convenio con TGR por folios en notificación y/o inicio',
  REGULARIZE_REFUND: 'Regularizar reintegro',
  REGULARIZE_REPLACEMENT: 'Regularizar reemplazo',
  COLLECTION_DELINQUENT_PURCHASEORDER: 'Recaudación de ordering moroso en cartera',
  PREPAY_OVERDUE_INVOICE: 'Cliente debe prepagar factura morosa',
  PAY_PAYMENTPLAN_INSTALLMENT: 'Pagar cuota de plan de pago',
  REGULARIZE_REFUND_OR_REPLACEMENT: 'Regularizar reintegro o reemplazo',
  OTHER: 'Otro',
};

export const CONTRACT_TYPE_TO_SPANISH = {
  NOTARY: 'Notarial',
  DIGITAL: 'Digital',
};

export const useStatusStyles = () => {
  const theme = useTheme();

  return {
    CREATED: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.common.white,
    },
    PENDING: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
    },
    RESOLVED: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
    FORCED: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
    EXPIRED: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    DELETED: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.common.white,
    },
    DEFAULT: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.primary,
    },
  };
};
